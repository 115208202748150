<script setup>
// import JurrasicIcon from "../../../assets/ecommerce/jurrasic.png";
// import ShoppingCart from "../../../assets/ecommerce/ShoppingCart.svg";
// import { Search } from "@element-plus/icons-vue";
// import BkDellIcon from "../../../assets/ecommerce/BKDell.png";
// import HindsightIcon from "../../../assets/ecommerce/HindSight.png";
// import TempsIcon from "../../../assets/ecommerce/Temps.png";
import deviceBreakpoint from "../../../mixins/deviceBreakpoint";
import { ref, computed, inject, watchEffect, onMounted } from "vue";
import axios from "../public/baseurl/backendbaseurl";
// import axios from "@/gateway/backendapi";
import router from "../../../router";
import { useStore } from "vuex";
import { useRoute } from "vue-router";
import ProductCard from "./component/ProductCard.vue";
import { ElLoading } from "element-plus";
import { ElMessage } from "element-plus";

const paymentGatewayModal = ref(false);
const paymentFailed = ref(false);
const paymentDone = ref(false);
const { mdAndUp, lgAndUp, xlAndUp, xsOnly } = deviceBreakpoint();

// const searchQuery = ref("");
const route = useRoute();
const primarycolor = inject("primarycolor");
const orderData = ref({});
const loading = ref(false);
const categoryItem = ref([]);
const allProducts = ref([]);
const productData = ref({});
const categoryProducts = ref([]);
const activeCategory = ref(null);
const isLoggedIn = ref(false);
// const isSignUpIn = ref(false);
// const selectedCurrency = ref({});
const tenantId = computed(() => store.getters["ecommerce/getId"]);
const sessionTennantId = ref(sessionStorage.getItem("tenantId"));
const userId = ref(localStorage.getItem("userId"));
const cartItems = ref(JSON.parse(localStorage.getItem("cartItems")) || []);
const store = useStore();
// const tenantId = ref(currentUser.value.tenantId);

const getAllProduct = async () => {
  try {
    const { data } = await axios.get(`/Ecommerce/GetAllProducts/${route.query.id}`);
    console.log(data, "getAllProduct");
    allProducts.value = data;
  } catch (error) {
    console.log(error);
  }
};
getAllProduct();

onMounted(() => {
  store.dispatch("ecommerce/setId", route.query.id);
  sessionStorage.setItem("tenantId", route.query.id);
});
const getAllProductInfo = async () => {
  try {
    const { data } = await axios.get(`/Ecommerce/GetStoreInformation/${route.query.id}`);
    console.log(data, "getAllProductInfo");
    productData.value = data;
    categoryItem.value = data.categories;
  } catch (error) {
    console.log(error);
  }
};
getAllProductInfo();
// const setActiveCategory = (categoryId) => {
//   activeCategory.value = categoryId;
// };
const setActiveCategory = (categoryId) => {
  console.log(categoryId, "ghshh");

  activeCategory.value = categoryId.id;
  const category = categoryItem.value.find((cat) => cat.id === categoryId);
  categoryProducts.value = category ? category.products : [];
};
const tryAgain = () => {
  paymentFailed.value = false;
};

onMounted(async () => {
  await getAllProductInfo(); // Fetch categories before setting the default active category
  if (categoryItem.value.length) {
    activeCategory.value = categoryItem.value[0].id; // Set the first category as active
    categoryProducts.value = categoryItem.value[0].products; // Display the first category's products
    console.log(activeCategory.value, "activeCategory");
    console.log(categoryProducts.value, "activeCategory");
  }
});

const filteredProducts = computed(() => {
  if (!activeCategory.value) return [];
  const category = categoryItem.value.find(
    (category) => category.id === activeCategory.value
  );
  return category ? category.products : [];
});

// const categoryProduct = (item) => {
//   console.log(item, "gdhhdhs");
//   categoryProducts.value = item.products;
//   console.log(categoryProducts.value, "each product");
// };
const addToCart = (product) => {
  // const cartItems = ref(JSON.parse(localStorage.getItem("cartItems")) || []);
  const existingProduct = cartItems.value.find((item) => item.id === product.id);
  if (existingProduct) {
    existingProduct.quantity++;
  } else {
    cartItems.value.push({ ...product, quantity: 1 });
  }
  localStorage.setItem("cartItems", JSON.stringify(cartItems.value)); // Update local storage
  store.dispatch("ecommerce/setIncrementCart");

  // Force localStorage to re-trigger the watchEffect in Navbar
  // const event = new Event("storage");
  // window.dispatchEvent(event);
};
// const productItem = (item) => {
//   console.log(item, "itemproduct");
//   router.push(`/store/bookdetails?productId=${item.id}`);
//   localStorage.setItem("singleProduct", JSON.stringify(item));
// };
const removeItem = (id) => {
  cartItems.value = cartItems.value.filter((item) => item.id !== id);
  store.dispatch("ecommerce/removeItemFromCart", id);
  store.dispatch("ecommerce/setCartCount", cartItems.value.length);
  localStorage.setItem("cartItems", JSON.stringify(cartItems.value));
};

const subtotal = computed(() => {
  return cartItems.value.reduce((acc, item) => acc + item.price * item.quantity, 0);
});
const increment = (item) => {
  item.quantity++;
  localStorage.setItem("cartItems", JSON.stringify(cartItems.value));
};

const decrement = (item) => {
  if (item.quantity > 1) {
    item.quantity--;
    localStorage.setItem("cartItems", JSON.stringify(cartItems.value));
  }
};
watchEffect(() => {
  localStorage.setItem("cartItems", JSON.stringify(cartItems.value));
});

const currentUser = computed(() => {
  if (
    !store.getters.currentUser ||
    (store.getters.currentUser && Object.keys(store.getters.currentUser).length == 0)
  )
    return "";
  return store.getters.currentUser;
});

const paymentSuccessful = () => {
  router.push(
    `/store/home?id=${tenantId.value ? tenantId.value : sessionTennantId.value}`
  );
};

const addOrder = async () => {
  loading.value = true;
  let payload = {
    totalAmount: subtotal.value,
    mobileUserID: userId.value,
    orderItems: cartItems.value.map((i) => {
      return {
        ecommerceProductID: i.id,
        quantity: i.quantity,
        priceAtPurchase: i.price,
      };
    }),
  };

  isLoggedIn.value = localStorage.getItem("loggedIn");
  // isSignUpIn.value = localStorage.getItem("signedUp");
  if (isLoggedIn.value) {
    try {
      const { data } = await axios.post(
        `/Ecommerce/AddOrder/${tenantId.value ? tenantId.value : sessionTennantId.value}`,
        payload
      );
      console.log(data, "");
      orderData.value = data;
      if (data) {
        paymentGatewayModal.value = true;
      } else {
        paymentGatewayModal.value = false;
      }

      loading.value = false;
    } catch (error) {
      loading.value = false;
      console.log(error);
    }
    // router.push('/store/checkout')
    // paymentGatewayModal.value = true;
  } else {
    router.push("/store/login");
  }
};
// const showPaymentGateway = () => {

// };

const initializePayment = (paymentGateway) => {
  const loading = ElLoading.service({
    lock: true,
    text: "Please wait...",
    background: "rgba(255, 255, 255, 0.9)",
  });
  axios
    .get(
      `/InitializeEcommercePayment?orderId=${orderData.value.id}&tenantId=${
        orderData.value.tenantID
      }&paymentGateway=${paymentGateway === 0 ? "Paystack" : "Flutterwave"}`
    )
    .then(({ data }) => {
      console.log(data, "sgshhsh");
      // close.value.click();
      // initializedOrder.value = res.data;
      loading.close();
      if (data.status) {
        if (paymentGateway == 0) {
          payWithPaystack(data);
        } else {
          payWithFlutterwave(data);
        }
      }
    });
};
const loadFlutterwaveScript = () => {
  return new Promise((resolve, reject) => {
    if (typeof window.FlutterwaveCheckout !== "undefined") {
      resolve(true);
    } else {
      const script = document.createElement("script");
      script.src = "https://checkout.flutterwave.com/v3.js";
      script.onload = () => resolve(true);
      script.onerror = () => reject(new Error("Flutterwave script failed to load"));
      document.head.appendChild(script);
    }
  });
};

const payWithFlutterwave = async (responseObject) => {
  console.log(responseObject, "flutterwave");

  // let country = "";

  // switch (selectedCurrency.value.shortCode) {
  //   case "KES":
  //     country = "KE";
  //     break;
  //   case "GHS":
  //     country = "GH";
  //     break;
  //   case "ZAR":
  //     country = "ZA";
  //     break;
  //   case "TZS":
  //     country = "TZ";
  //     break;

  //   default:
  //     country = "NG";
  //     break;
  // }
  try {
    await loadFlutterwaveScript(); // Ensure the script is loaded before using FlutterwaveCheckout

    window.FlutterwaveCheckout({
      // public_key: process.env.VUE_APP_FLUTTERWAVE_TEST_KEY,
      public_key: process.env.VUE_APP_FLUTTERWAVE_PUBLIC_KEY_LIVE,
      tx_ref: responseObject.transactionReference,
      amount: responseObject.amount,
      currency: responseObject.tenant.country.currency,
      country: responseObject.tenant.country.name,
      payment_options: "card,ussd",
      customer: {
        name:
          responseObject && responseObject.tenant
            ? responseObject.tenant.name
            : currentUser.value.churchName,
        email:
          responseObject && responseObject.tenant
            ? responseObject.tenant.email
            : currentUser.value.userEmail,
      },
      callback: (response) => {
        let trans_id = response.transaction_id;
        let tx_ref = response.tx_ref;
        subscriptionPayment(trans_id, tx_ref);
      },
      onclose: () => console.log("Payment closed"),
      customizations: {
        title: "Subscription",
        description: "Payment for Subscription",
      },
    });
  } catch (error) {
    console.error("Flutterwave script error:", error);
  }
};

const payWithPaystack = (responseObject) => {
  /*eslint no-undef: "warn"*/
  let handler = PaystackPop.setup({
    key: process.env.VUE_APP_PAYSTACK_PUBLIC_KEY_LIVE,
    // key: process.env.VUE_APP_PAYSTACK_API_KEY,
    email: "info@churchplus.co",
    amount: responseObject.amount * 100,
    // amount: TotalAmount.value * 100,
    // currency: selectedCurrency.value.shortCode,
    currency: responseObject.tenant.country.currency,
    // country: responseObject.tenant.country.name,
    channels: ["card", "bank", "ussd", "qr", "mobile_money", "bank_transfer"],
    ref: responseObject.transactionReference,
    onClose: function () {
      ElMessage({
        type: "info",
        showClose: true,
        message: "You have cancelled the transaction",
        duration: 5000,
      });
    },
    callback: function (response) {
      let trans_id = response.trxref;
      let tx_ref = response.trxref;
      subscriptionPayment(tx_ref, trans_id);
    },
  });
  handler.openIframe();
};
const subscriptionPayment = async (trans_id, tx_ref) => {
  try {
    await axios
      .post(`/ConfirmEcommercePayment?id=${trans_id}&txnref=${tx_ref}`)
      .then((res) => {
        console.log(res);
        // display.value = true;
        if (res.data) {
          paymentFailed.value = false;
          paymentDone.value = true;
          localStorage.removeItem("cartItems"); // Clear cart items (optional)
        } else {
          paymentFailed.value = true;
        }
      })
      .catch((err) => {
        console.log(err);
        // display.value = true;
        paymentFailed.value = true;
        paymentDone.value = false;
      });
  } catch (error) {
    console.log(error);
    paymentDone.value = false;
    paymentFailed.value = true;
    ElMessage({
      type: "error",
      showClose: true,
      message: "Transaction Failed",
      duration: 5000,
    });
  }
};

// const showPaymentGateway = () => {
//   isLoggedIn.value = localStorage.getItem("loggedIn");
//   if (isLoggedIn.value) {
//     paymentGatewayModal.value = true;
//   } else {
//     router.push("/store/login");
//   }
// };
</script>
<template>
  <main>
    <div class="container-fluid mt-5">
      <div class="row">
        <div class="col-md-12 d-flex flex-wrap">
          <div
            class="col-md-12 px-0 mb-3 d-flex align-items-center justify-content-center"
          >
            <div
              style="background: #f30e15"
              class="font-weight-600 py-2 col-md-6 mt-3 text-center text-white d-block d-md-none"
            >
              Sort Category
            </div>
            <div class="col-md-6 bg-gray-500 mt-3 py-2 d-block d-md-none">
              <el-dropdown class="col-md-12">
                <div class="w-100 d-flex justify-content-between align-items-center">
                  <span class="el-dropdown-link text-dak mt-1">
                    {{
                      activeCategory && activeCategory.categoryName
                        ? activeCategory.categoryName
                        : "Category"
                    }}
                  </span>
                  <span>
                    <el-icon class="el-icon--right">
                      <arrow-down />
                    </el-icon>
                  </span>
                </div>
                <template #dropdown>
                  <el-dropdown-menu>
                    <el-dropdown-item
                      v-for="(itm, index) in categoryItem"
                      :key="index"
                      @click="setActiveCategory(itm)"
                      class="cursor-pointer"
                      >{{ itm.categoryName }}</el-dropdown-item
                    >
                  </el-dropdown-menu>
                </template>
              </el-dropdown>
            </div>
          </div>
          <div class="col-md-2">
            <div
              class="col-md-11 d-none d-md-block bg-gray-600 pt-1 radius-13 mb-4"
              style="height: 35rem"
            >
              <div
                style="background: #f30e15"
                class="font-weight-600 py-2 col-md-12 mt-3 text-center text-white"
              >
                Sort Category
              </div>
              <div
                class="cursor-pointer hover-category mt-2"
                v-for="(item, index) in categoryItem"
                :key="index"
                @click="setActiveCategory(item)"
              >
                <span
                  :class="{ active: activeCategory === item.id }"
                  class="d-flex align-items-center mt-3 justify-content-between"
                >
                  <span>{{ item.categoryName }}</span>
                  <span
                    ><el-icon><ArrowRight /></el-icon
                  ></span>
                </span>
              </div>
            </div>
            <div class="col-md-12 d-none d-md-block">
              <div class="s-22 font-weight-bold">Product Type</div>
              <div class="s-18 mt-2 fw-400">Audiobook</div>
              <div class="s-18 mt-2 fw-400">E-book</div>
              <div class="s-18 mt-2 fw-400">Hardcopy</div>
              <div class="s-18 mt-2 fw-400">Video</div>
              <div class="s-18 mt-2 fw-400">Physical Product</div>
            </div>
            <div class="col-md-12 mt-5 d-none d-md-block">
              <img
                src="../../../assets/tempImage/categoryIcon.png"
                class="w-100"
                alt=""
              />
            </div>
          </div>
          <div class="col-md-7 px-0">
            <div class="row">
              <div
                v-if="productData && productData.bannerURL"
                class="hero-section col-md-12 pb-4"
              >
                <!-- <img
                  src="../../../assets/tempImage/bannerIcon.png"
                  class="w-100 img-adjust"
                  alt=""
                /> -->
                <img :src="productData.bannerURL" class="w-100 img-adjust" alt="" />
              </div>
            </div>
            <div class="row">
              <div class="col-md-12 mt-4 border-radius-8">
                <div class="row">
                  <!-- <div class="col-md-12">
                    <div class="row">
                      <div class="col-md-9 d-flex flex-wrap justify-content-between">
                        <div
                          class="cursor-pointer cursor-pointer hover-category mt-2"
                          v-for="(item, index) in categoryItem"
                          :key="index"
                          :class="{ active: activeCategory === item.id }"
                          @click="setActiveCategory(item.id)"
                        >
                          {{ item.categoryName }}
                        </div>
                      </div>
                    </div>
                  </div> -->
                  <!-- @click="productItem(product)" -->
                  <div class="col-md-12 mb-2 s-20 fw-400">
                    Recommended for you
                    <!-- <span class="font-weight-bold">(50% off Sales)</span> -->
                  </div>
                  <div
                    class="col-md-4 col-sm-6 col-12 cursor-pointer"
                    v-for="product in filteredProducts"
                    :key="product.id"
                  >
                    <ProductCard
                      v-if="filteredProducts && filteredProducts.length > 0"
                      :product="product"
                      :key="product.id"
                      :id="product.id"
                      :image="product.imageURL"
                      :title="product.productName"
                      :review="product.reviews"
                      :price="product.price"
                      @add-to-cart="addToCart"
                    />
                  </div>
                  <div
                    class="col-md-12 mt-5 text-center"
                    v-if="filteredProducts && filteredProducts.length < 0"
                  >
                    No products available for this category.
                  </div>
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col-md-12 mt-4">
                <div class="row">
                  <div class="col-md-12">
                    <div class="row">
                      <div class="col-md-11 s-22 font-weight-bold">Top Picks</div>
                    </div>
                    <!-- <div class="row">
                      <div class="col-md-11 s-22">
                        Recommended for you
                        <span class="font-weight-bold">(50% off Sales)</span>
                      </div>
                    </div> -->
                  </div>
                  <!-- @click="productItem(product)" -->

                  <div
                    class="col-md-4 col-sm-6 col-12 px-0 cursor-pointer"
                    v-for="product in allProducts"
                    :key="product.id"
                  >
                    <ProductCard
                      v-if="allProducts && allProducts.length > 0"
                      :product="product"
                      :id="product.id"
                      :image="product.imageURL"
                      :title="product.productName"
                      :review="product.reviews"
                      :price="product.price"
                      @add-to-cart="addToCart"
                    />
                    <div class="col-md-12 mt-5 text-center" v-else>
                      No products available
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="col-md-3">
            <div class="row justify-content-center">
              <div class="col-md-11">
                <img
                  src="../../../assets/tempImage/saleIcon.png"
                  class="w-100 h-100"
                  style="object-fit: contain; height: auto"
                  alt=""
                />
              </div>
            </div>
            <div class="row mt-4 justify-content-center">
              <div class="col-md-10 px-0">
                <img
                  src="../../../assets/tempImage/pcIcon.png"
                  class="w-100 h-100"
                  style="object-fit: contain; height: auto"
                  alt=""
                />
              </div>
            </div>
            <div class="row justify-content-center">
              <div class="col-md-10 px-0 border-radius-border-8 mt-4">
                <div class="font-weight-600 col-md-12 mt-2">Cart Preview</div>
                <div
                  class="col-md-12 px-0"
                  :class="{ cartOverflow: cartItems.length == 2 }"
                >
                  <div
                    class="car p-2 mt-3 col-md-12 border-botom mb-3"
                    v-for="item in cartItems"
                    :key="item.id"
                  >
                    <div class="row no-gutters align-items-cener">
                      <div class="col-md-2">
                        <img :src="item.image" class="card-img h-100" alt="" />
                      </div>
                      <div class="col-md-8">
                        <div class="card-bod px-2">
                          <h5 class="card-title fw-500 s-12">{{ item.title }}</h5>
                          <div class="card-text d-flex fw-400 s-10">
                            <img
                              src="../../../assets/ecommerce/audioBookIcon.png"
                              alt=""
                            />
                            <span>AudioBook</span>
                          </div>
                          <div>
                            <div class="mb-0">
                              <span class="s-12 fw-500 text-dak"
                                >NGN {{ Math.abs(item.price).toLocaleString() }}</span
                              >
                            </div>
                          </div>
                        </div>
                      </div>
                      <div
                        class="col-md-2 d-flex flex-column align-items-end justify-content-between"
                      >
                        <div
                          class="cursor-pointer text-danger"
                          @click="removeItem(item.id)"
                        >
                          <el-icon :size="20"><Delete /></el-icon>
                        </div>
                      </div>
                      <div class="d-flex px-0 col-md-12 justify-content-end">
                        <div class="d-flex align-items-center">
                          <div class="fw-400 s-10 mr-1">Quantity</div>
                          <div
                            class="input-group-prepend cursor-pointer"
                            @click="increment(item)"
                          >
                            <div>+</div>
                          </div>
                          <div>
                            <div class="border rounded px-2 mx-2">
                              {{ item.quantity }}
                            </div>
                          </div>
                          <div
                            class="input-group-prepend cursor-pointer p-2"
                            @click="decrement(item)"
                          >
                            <div>-</div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="col-md-12">
                  <div class="row" v-if="cartItems.length > 0">
                    <div class="col-md-12 d-flex justify-content-center">
                      <div
                        class="col-md-10 text-black d-flex justify-content-center align-items-center"
                      >
                        <span class="s-12 fw-500"
                          >SubTotal : NGN {{ Math.abs(subtotal).toLocaleString() }}</span
                        >
                      </div>
                    </div>
                    <div class="col-md-12 px-0 mt-2 mb-4 d-flex justify-content-center">
                      <div class="col-md-10 px-0">
                        <div @click="addOrder" class="col-md-12 px-0">
                          <el-button round color="#1B1918" class="w-100 text-white"
                            >Checkout</el-button
                          >
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="row mt-5" v-else>
                    <div class="col-md-12 text-center">
                      <p class="s-18">No items in your cart</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div>
      <el-dialog
        class=""
        style="border-radius: 25px"
        v-model="paymentGatewayModal"
        title=""
        :width="mdAndUp || lgAndUp || xlAndUp ? `50%` : xsOnly ? `90%` : `70%`"
      >
        <div class="row justify-content-center">
          <div class="col-md-10 col-11 mt-4 h-100 bg-white mb-5">
            <div class="row justify-content-center align-items-center">
              <div class="col-md-10 d-flex justify-content-center">
                <div class="text-head font-weight-bold h3 pl-2">Payment methods</div>
              </div>
              <div class="col-md-12 mt-2 d-flex justify-content-center">
                <div class="col-md-7 col-12 col-sm-8">
                  <div class="col-sm-12 p-4 text-center continue-text">
                    Continue payment with
                  </div>
                </div>
              </div>
              <div class="col-md-12 mt-2">
                <div
                  class="row justify-content-center c-pointer"
                  @click="initializePayment(0)"
                >
                  <!-- <div
                  class="row justify-content-center c-pointer"
                  @click="initializePayment(0)"
                  v-if="currentUser.currency == 'NGN' || currentUser.currency == 'GHS'"
                > -->
                  <div class="col-md-8 border py-3 row-button">
                    <div class="row c-pointer justify-content-center">
                      <div class="col-md-6">
                        <img
                          class="w-100"
                          src="../../../assets/mobileonboarding/paystack.png"
                          alt="paystack"
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  class="row c-pointer mt-3 justify-content-center"
                  @click="initializePayment(1)"
                >
                  <div class="col-md-8 border py-2 row-button">
                    <div class="row c-pointer justify-content-center">
                      <div class="col-md-7">
                        <img
                          class="w-100"
                          src="../../../assets/mobileonboarding/flutterwave.png"
                          alt="flutterwave"
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </el-dialog>
      <el-dialog
        v-model="paymentDone"
        style="border-radius: 20px"
        title=""
        :width="mdAndUp || lgAndUp || xlAndUp ? `50%` : xsOnly ? `90%` : `70%`"
        align-center
      >
        <div class="row">
          <div class="col-12">
            <div class="d-flex justify-content-center">
              <img
                src="../../../assets/successful_payment.png"
                style="width: 250px; margin: auto"
              />
            </div>
            <h3 class="text-center mt-5 font-weight-bold success">Thank you</h3>
            <div class="text-center mt-2 font-weight-600 s-18">
              Payment completed successfully
            </div>
            <div class="d-flex justify-content-center mb-5">
              <el-button
                color="#70c043"
                class="text-white mt-2"
                @click="paymentSuccessful"
                round
                >Go back</el-button
              >
            </div>
          </div>
        </div>
      </el-dialog>
      <el-dialog
        class=""
        style="border-radius: 25px"
        v-model="paymentFailed"
        title=""
        :width="mdAndUp || lgAndUp || xlAndUp ? `50%` : `90%`"
      >
        <div class="row justify-content-center">
          <div class="col-md-10 mt-4 h-100 bg-white mb-5">
            <div class="row justify-content-center align-items-center">
              <div class="col-md-10 d-flex justify-content-center">
                <div class="col-md-6">
                  <img
                    class="w-100"
                    src="../../../assets/mobileonboarding/sub-failed.png"
                    alt=""
                  />
                </div>
              </div>
              <div class="col-md-12 d-flex justify-content-center">
                <div class="col-md-6 col-8">
                  <div
                    class="text-head font-weight-bold col-md-12 px-0 h2 text-center"
                    style="color: #d21615"
                  >
                    Payment failed!
                  </div>
                </div>
              </div>
              <div class="col-md-10 d-flex my-5 justify-content-center">
                <div class="col-md-8">
                  <el-button
                    @click="tryAgain"
                    :color="primarycolor"
                    size="large"
                    class="w-100"
                    round
                    >Try again</el-button
                  >
                </div>
              </div>
            </div>
          </div>
        </div>
      </el-dialog>
    </div>
  </main>
</template>
<style scoped>
.cartOverflow {
  overflow-y: scroll;
  height: 20rem !important;
}

.img-adjust {
  object-fit: cover;
  height: 257px !important;
}
.card-img {
  max-width: 100%;
  height: 5rem !important;
  object-fit: cover;
}

.quantity-group {
  max-width: 150px;
}
.card-text img {
  height: 100%;
  width: 1rem;
}
.hover-category:hover {
  font-weight: 500;
  font-size: 16px;
}
.active {
  font-weight: 500; /* Bold text for active item */
  color: #000000; /* Bootstrap primary color or any custom color */
  /* width: 100%; */
}

.cart-icon {
  transform: rotate(0deg);
  align-items: right;
}
.border-botom {
  border-bottom: 1px solid #979494;
}
.continue-text {
  font-size: 16px;
  font-weight: 500;
  text-align: center;
}
.row-button {
  border-radius: 50px;
  box-shadow: 0 4px 12px rgb(0 0 0 / 10%);
  background: #f3f3f3;
  transition: all 0.4s ease-in-out;
  border: 1px solid #0000002b;
}
</style>
